import { Cookies } from 'react-cookie';

const cookie = new Cookies();

export const setCookie = (key: string, value: string, option?: any) => {
  const defaultOptions = {
    path: '/' // 여기에 경로를 설정하십시오
  };

  // 기존의 쿠키를 먼저 제거합니다.
  cookie.remove(key, { path: '' });

  return cookie.set(key, value, { ...defaultOptions, ...option });
};

export const getCookie = (key: string) => {
  return cookie.get(key);
};

export const removeCookie = (key: string, option?: any) => {
  const defaultOptions = {
    path: '/' // 여기에 경로를 설정하십시오
  };

  return cookie.remove(key, { ...defaultOptions, ...option });
};
