import { useLocation, matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation();

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;
  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  const isParentActive = path && path !== '/admin' ? !!matchPath({ path: `${path}/*`, end: false }, pathname) : false;

  return deep ? deepActive : normalActive || isParentActive;
}
