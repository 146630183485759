import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  alert: icon('ic_alert')
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------

      {
        subheader: 'OVERVIEW',
        items: [{ title: '대시보드', path: paths.dashboard.root, icon: ICONS.analytics }]
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'MANAGEMENT',
        items: [
          {
            title: '회원 관리',
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              { title: '개인 회원', path: paths.dashboard.user.common },
              { title: '기업 마스터', path: paths.dashboard.user.company },
              { title: '파트너 회원', path: paths.dashboard.user.partner }
            ]
          },
          {
            title: '지점 관리',
            path: paths.dashboard.branch.root,
            icon: ICONS.tour,
            children: [
              { title: '지점 목록', path: paths.dashboard.branch.list },
              { title: '지점 등록', path: paths.dashboard.branch.add }
            ]
          },
          {
            title: '상품 관리',
            path: paths.dashboard.product.root,
            icon: ICONS.ecommerce,
            children: [
              { title: '상품 목록', path: paths.dashboard.product.list },
              { title: '상품 등록', path: paths.dashboard.product.add }
            ]
          },
          { title: '부대시설 관리', path: paths.dashboard.facility.root, icon: ICONS.file }
        ]
      },
      {
        subheader: 'ORDER',
        items: [
          {
            title: '예약 결제 관리',
            path: paths.dashboard.order.root,
            icon: ICONS.order,
            children: [
              { title: '워케이션 예약내역', path: paths.dashboard.order.workation },
              { title: '커뮤니티 예약내역', path: paths.dashboard.order.community },
              { title: '커머스 구매내역', path: paths.dashboard.order.shopping }
            ]
          }
        ]
      },
      {
        subheader: 'FINANCIAL',
        items: [
          {
            title: '크레딧 관리',
            path: paths.dashboard.credit.root,
            icon: ICONS.invoice,
            children: [
              { title: '충전 내역', path: paths.dashboard.credit.charge },
              { title: '지급 내역', path: paths.dashboard.credit.payment },
              { title: '환불 내역', path: paths.dashboard.credit.refund },
              { title: '회수 내역', path: paths.dashboard.credit.recovery }
            ]
          },
          {
            title: '정산 관리',
            path: paths.dashboard.calculate.root,
            icon: ICONS.calendar,
            children: [{ title: '정산 내역', path: paths.dashboard.calculate.history }]
          },
          {
            title: '쿠폰 관리',
            path: paths.dashboard.coupon.root,
            icon: ICONS.label,
            children: [
              { title: '발행 내역', path: paths.dashboard.coupon.list },
              { title: '쿠폰 설정', path: paths.dashboard.coupon.setting }
            ]
          }
        ]
      },
      {
        subheader: 'CUSTOMER SERVICE',
        items: [
          {
            title: '리뷰관리',
            path: paths.dashboard.review.root,
            icon: ICONS.blog,
            children: [
              { title: '사용자 리뷰', path: paths.dashboard.review.list },
              { title: 'SNS 리뷰', path: paths.dashboard.review.sns }
            ]
          },
          {
            title: '홈페이지 관리',
            path: paths.dashboard.homepage.root,
            icon: ICONS.folder,
            children: [
              { title: '자주 묻는 질문', path: paths.dashboard.homepage.faq },
              { title: '소식 및 뉴스', path: paths.dashboard.homepage.news }
            ]
          },
          {
            title: '알림 서비스',
            path: paths.dashboard.notification.root,
            icon: <Iconify icon="fluent:alert-48-filled" width={24} />
          }
        ]
      }
    ],
    []
  );

  return data;
}
