import {atom} from 'recoil';

interface faqAddProps {
  title: string;
  content: string;
}

export const faqStore = atom<faqAddProps>({
  key:'faqStore',
  default: {
    title: '',
    content: ''
  }
})
