import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import { useRecoilValue } from 'recoil';
import { authStore } from 'src/recoil';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();

  // const { authenticated, method } = useAuthContext();

  const authenticated = useRecoilValue(authStore);

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated.EMAIL) {
      // const searchParams = new URLSearchParams({
      //   returnTo: window.location.pathname
      // }).toString();

      router.replace(paths.auth.jwt.login);
    } else {
      setChecked(true);
    }
  }, [authenticated, router]);
  // method,

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
