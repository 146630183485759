import { atom } from 'recoil';

export interface SnackBarStoreType {
  open: boolean;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
}

// Toast 메시지를 관리하는 recoil atom
export const snackBarStore = atom<SnackBarStoreType>({
  key: 'snackBar',
  default: {
    open: false,
    message: '',
    type: 'success'
  }
});
