const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/admin'
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`
    }
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      common: `${ROOTS.DASHBOARD}/user/common`,
      company: `${ROOTS.DASHBOARD}/user/company`,
      partner: `${ROOTS.DASHBOARD}/user/partner`,
      commonAddUser: `${ROOTS.DASHBOARD}/user/common/add`
    },
    branch: {
      root: `${ROOTS.DASHBOARD}/branch`,
      list: `${ROOTS.DASHBOARD}/branch/list`,
      add: `${ROOTS.DASHBOARD}/branch/add`
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      list: `${ROOTS.DASHBOARD}/product/list`,
      approve: `${ROOTS.DASHBOARD}/product/approve`,
      add: `${ROOTS.DASHBOARD}/product/add`
    },
    facility: {
      root: `${ROOTS.DASHBOARD}/facility`
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order/`,
      workation: `${ROOTS.DASHBOARD}/order/workation`,
      community: `${ROOTS.DASHBOARD}/order/community`,
      shopping: `${ROOTS.DASHBOARD}/order/shopping`
    },
    credit: {
      root: `${ROOTS.DASHBOARD}/credit`,
      charge: `${ROOTS.DASHBOARD}/credit/charge`,
      payment: `${ROOTS.DASHBOARD}/credit/payment`,
      refund: `${ROOTS.DASHBOARD}/credit/refund`,
      recovery: `${ROOTS.DASHBOARD}/credit/recovery`
    },
    calculate: {
      root: `${ROOTS.DASHBOARD}/calculate`,
      history: `${ROOTS.DASHBOARD}/calculate/history`,
      detail: `${ROOTS.DASHBOARD}/calculate/detail`,
      settlement: `${ROOTS.DASHBOARD}/calculate/settlement`
    },
    coupon: {
      root: `${ROOTS.DASHBOARD}/coupon`,
      list: `${ROOTS.DASHBOARD}/coupon/list`,
      setting: `${ROOTS.DASHBOARD}/coupon/setting`
    },
    review: {
      root: `${ROOTS.DASHBOARD}/review`,
      list: `${ROOTS.DASHBOARD}/review/list`,
      sns: `${ROOTS.DASHBOARD}/review/sns`
    },
    inquiry: {
      root: `${ROOTS.DASHBOARD}/inquiry`,
      company: `${ROOTS.DASHBOARD}/inquiry/company`,
      partner: `${ROOTS.DASHBOARD}/inquiry/partner`
    },
    homepage: {
      root: `${ROOTS.DASHBOARD}/homepage`,
      faq: `${ROOTS.DASHBOARD}/homepage/faq`,
      news: `${ROOTS.DASHBOARD}/homepage/news`,
      add: `${ROOTS.DASHBOARD}/homepage/news/add`
    },
    notification: {
      root: `${ROOTS.DASHBOARD}/notification`
    }
  },

  page403: '/403',
  page404: '/404',
  page500: '/500'
};
